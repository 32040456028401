.app__products {
	flex-direction: column;
	background-color: var(--color-black);
}

.app__products-title {
	margin-bottom: 2rem;
	text-align: center;
}

.app__products-range {
	width: 100%;
	margin: 2rem 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;
}

.app__products-range-heading {
	font-family: var(--font-base);
	font-weight: 600;
	font-size: 45px;
	line-height: 58.5px;
	letter-spacing: 0.04em;
	color: var(--color-white);
}

.app__products-range_perfumes,
.app__products-range_colognes {
	flex: 1;
	width: 100%;
	flex-direction: column;
}

/* .app__products-range-img {
	border: 1px solid white;
  display: flex;
  justify-content: center;
	width: 410px;
	margin: 0 2rem;
}
.app__products-range-img img {
	width: 100%;
	height: auto;
	border: 1px solid white;
} */

.app__products-range-img {
	width: 410px;
	margin: 0 2rem;
	/* border: 1px solid white; */
	display: flex;
	justify-content: center;
}


.app__products-range-img img {
	width: 100%;
	height: auto;
	/* border: 1px solid white; */
}

.app__products-range_items {
	display: flex;
	flex-direction: column;
	margin: 2rem 0;

	width: 100%;
}

@media screen and (max-width: 650px) {
	.app__products-range-img {
		width: 100%;
	}
	.app__products-range-heading {
		font-size: 35px;
		line-height: 48.5px;
	}
}

/* app__specialMenu-menu_img */

@media screen and (max-width: 1150px) {
	.app__products-range-img {
		width: 100%;
		height: 100%;
		margin: 3rem;
	}
	.app__products-range-img img {
		width: 70%;
		height: 70%;
	}
	.app__products-range {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.app__products-range_colognes {
		margin-top: 3rem;
	}
}

@media screen and (min-width: 1150px) {
	.app__products-range-img img {
		margin-top: 3rem;
	}
}
